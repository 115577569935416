/* eslint-disable react/no-danger */
import React, { useState, useEffect, useContext } from 'react';
import ReactHTMLParser from 'react-html-parser';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { marked } from 'marked';

import { FeaturesSlider } from '@latitude/features-slider';
import HowToApply from '@latitude/how-to-apply';

import { getUrlFromEnv } from '@/utils/getUrlFromEnvUtil';
import Layout from '@/components/layout';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import Faq from '@/components/Faq/Faq';
import LoanRates from '@/components/LoanRates/LoanRates';
import PLData from '@/data/pages/personal-loan.json';
import HowToApplyList from '@/components/HowToApply/HowToApplyList';
import { EstimateRateWidgetSection } from '@/components/EstimateRateWidget/EstimateRateWidgetSection';
import { redirectUrl } from '@/components/EstimateRateWidget/utils';
import FeatureTiles from '@/components/FeatureTiles/FeatureTiles';
import CalcData from '../data/pages/loans/loan-repayment-calculator.json';
import { PLEligibilityCriteria } from '@/components/PersonalLoanPageContent/PersonalLoanPageContent';
import FeesAndCharges from '@/components/PersonalLoanDetails/PersonalLoanDetails';
import { ImportantInformation } from '@latitude/important-information';
import {
  BREAKPOINT,
  COLOR,
  SOFT_QUOTE_LOANS_AU_URL,
  SITE_URL
} from '@/utils/constants';
import footerData from '@/data/json/footer.json';
import PageData from '../data/pages/boat-loan.json';
import heroImage from '../images/hero/boat-loan.webp';
import SvgInline from '@latitude/svg-inline';
import Button from '../components/Button/Button';
import PageHeaderSection from '@/components/PageLayout/PageHeader';
import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';

const HomeButton = styled(Button)`
  && {
    background-color: #21a637;
    color: #fff !important;
    white-space: nowrap;
    font-size: 22px;
  }

  @media (max-width: ${BREAKPOINT.MD}) {
    && {
      font-size: 15px;
      width: 100%;
      white-space: break-spaces;
    }
  }

  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }
`;

const BoatLoanPage = ({ location }) => {
  const applyUrl = `${getUrlFromEnv(
    'funnel-start-loans'
  )}${SOFT_QUOTE_LOANS_AU_URL}`;

  // soft quote
  const [softQuoteHref, setSoftQuoteHref] = useState();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};

  const featureSliderData = [
    {
      heading: 'Why choose a Latitude loan?',
      featureCards: PLData.content.whyChooseLatitude
    }
  ];
  /** - END - */

  useEffect(() => {
    sessionStorage.setItem('purpose', 'purpose'); // reset session val used in `EstimateRateWidgetSection`
    setSoftQuoteHref(redirectUrl('au', true));
  }, []);

  return (
    <Layout location={location} customFooter={footerData} hasStickyNav>
      <main
        className="navigation-spacer navigation-spacer--hasStickyNav"
        css="background-color:#f8f8f8;"
      >
        <Helmet>
          <link rel="canonical" href={`${SITE_URL}${location.pathname}`} />
          <title>{PageData.metaTitle}</title>
          <meta name="description" content={PageData.metaDesc} />
        </Helmet>
        {state?.heroBannerData?.[0] ? (
          <HeroBanner {...state?.heroBannerData[0]} />
        ) : (
          <PageHeaderSection
            title={<>Boat Loans</>}
            subTitle={
              <>
                Ready to take to the open water? Apply for a Latitude Boat Loan
                online in just 7 minutes{' '}
                <span className="conditional-text">
                  and get a response to your application in 60 seconds.
                </span>
              </>
            }
            pageImage={heroImage}
            pageImageAlt="boat loan"
          >
            <div className="text-white row">
              <div className="text-left col-6 col-md-5">
                <HomeButton
                  href={applyUrl}
                  trackId="personal-loans-button"
                  trackEventData={{
                    label: 'Get Rate Estimate',
                    location: 'Hero',
                    category: 'button-link'
                  }}
                  trackProductId={['PLAULF-WEB']}
                  className="button--primary"
                >
                  Get Rate Estimate
                </HomeButton>
              </div>
              <div className="p-0 text-center col-1">
                <SvgInline name="stopwatch" />
              </div>
              <div className="pt-1 text-left col-5 col-md-6 HeroContent__insettext">
                Check your interest rate in 2 minutes with no impact to your
                credit score.
              </div>
            </div>
          </PageHeaderSection>
        )}

        <div className="d-none d-lg-block" css="position:relative; z-index:10;">
          <StickyNavigationBranded
            items={PLData.nav}
            phoneNumber={PLData.content.phoneNumber}
            ctaHref={applyUrl}
            ctaText="Get Rate Estimate"
            offsetElem="[data-sticky-navigation-offset]"
            trackEventData={{
              category: 'cta',
              action: 'quote-link',
              location: 'sticky'
            }}
            trackId="sticky-get-started"
            {...state?.inPageNavData?.[0]}
          />
        </div>
        <div
          id="boat-loan-rate-container"
          css={`
            position: relative;
            z-index: 2;
            background-color: ${COLOR.GREY6};
          `}
        >
          <LoanRates
            isBranded
            rateBoxType="variableSym"
            rateBoxType2="fixedSym"
            fees={PLData.content.aboutTheLoan}
            determineRateLink
          />
        </div>
        <EstimateRateWidgetSection
          purpose="purpose"
          parentOnChangeEvent={() => {
            setSoftQuoteHref(redirectUrl());
          }}
        />
        <FeesAndCharges data={PLData.content.feesAndCharges} />
        <FeatureTiles
          id="calculators-and-tools"
          heading={CalcData.content.calculatorsTitle}
          tiles={CalcData.content.calculators}
        />
        <FeaturesSlider
          key={(state?.featureSliderData?.[0] || featureSliderData[0]).heading}
          id="why-us"
          data={
            (state?.featureSliderData?.[0] || featureSliderData[0]).featureCards
          }
          subheading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).description
          }
          className="pt-4 why-choose pt-md-5"
          heading={
            (state?.featureSliderData?.[0] || featureSliderData[0]).heading
          }
          css={`
            h2 {
              @media (min-width: ${BREAKPOINT.SM}) {
                font-size: 32px !important;
                line-height: 36px !important;
              }
            }
            && {
              background-color: #f8f8f8;
              padding-top: 40px !important;
              padding-bottom: 40px;

              .card-icon__image svg {
                fill: inherit !important;
                stroke: inherit !important;
              }

              @media (min-width: ${BREAKPOINT.LG}) {
                padding-top: 72px !important;
                padding-bottom: 49px;
              }
            }
          `}
        />
        <HowToApply
          id="how-to-apply"
          box1={<HowToApplyList variant="sym" />}
          box2={<PLEligibilityCriteria />}
        />
        <Faq data={PageData.content.faq} />
        <ImportantInformation
          data={require('@/data/json/disclaimer/personal-loan1.json')}
          sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
          sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
          additionalPaymentsDisclaimerOne
          {...state?.importantInfoData?.[0]}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'LoanOrCredit',
              name: PLData.content.loanDetails.personal.name,
              loanTerm: PLData.content.loanDetails.personal.loanTerm,
              annualPercentageRate:
                PLData.content.loanDetails.personal.annualPercentageRate,
              amount: PLData.content.loanDetails.personal.amount
            })
          }}
        />
      </main>
    </Layout>
  );
};

export default BoatLoanPage;
